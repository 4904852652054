:root {
  --primary-color: #FFFFFF;
  --secondary-color: #FFFFFF;
} 

.primary-color {
  color: var(--primary-color) !important;
}
.secondary-color {
  color: var(--secondary-color) !important;
}
.bg-primary-color {
  background-color: var(--primary-color) !important;
}
.bg-secondary-color {
  background-color: var(--secondary-color) !important;
}
.bg-secondary-color-20 {
  position: relative;
}
.bg-secondary-color-20::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--secondary-color); /* Original background color */
  opacity: 0.2; /* 50% opacity */
  z-index: -1; /* Place it behind the content */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

td, th {
  border: 1px solid #dddddd;
  padding: 10px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~react-image-gallery/styles/css/image-gallery.css";

/* https://stackoverflow.com/questions/60214721/how-do-i-shrink-the-size-of-an-image-in-react-image-gallery */

.image-gallery-slide img {
    width: 30rem;
    height: 30rem;
}

.ant-spin-dot-item {
  background-color: var(--primary-color) !important;
}

/* used for the loader - centers something in the middle of the page*/
.fixed-center {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  top: 40%;
}

.logo>img {
  width: 1050px;
  height: 100px;
  object-fit: scale-down;
  padding-bottom: 5px;
  padding-top: 5px;
  cursor: pointer;
  margin-left: 1rem;
}
.logo-small>img {
  width: 155px;
  height: 150px;
  object-fit: scale-down;
  padding-bottom: 5px;
  padding-top: 5px;
  cursor: pointer;
  margin-left: 1rem;
}

/* Height on mobile Image gallery only on mobile */
@media only screen and (max-width: 640px) {
  .image-gallery-slide .image-gallery-image {
    height: auto;
  }
}


.border-primary {
  border-color: var(--primary-color) !important;
}